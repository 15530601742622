export const adjective = {
	singular: "vendor",
	plural: "vendors",
};

export const verb = {
	singular: "working",
};

export const job = {
	singular: "job",
	plural: "jobs",
};
